const ossImgUrl = process.env.VUE_APP_OSS_IMG_URL;
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
export default {
  getOssImg: (url) => {
    return ossImgUrl + "/img/" + url;
  },
  costumPhone: (phone) => {
    if (phone == undefined || phone == null) {
      return "";
    } else {
      const reg = /^(\d{3})(\d{4})(\d{4})$/;
      var matches = reg.exec(phone);
      return matches[1] + " " + matches[2] + " " + matches[3];
    }
  },
  //手机号脱敏
  encryPhone: (phone) => {
    if (!phone) return phone;
    const reg = /^(\d{3})(\d{4})(\d{4})$/;
    var matches = reg.exec(phone);
    return matches[1] + "****" + matches[3];
  },
  //身份证号脱敏
  encryIdCard: (id) => {
    if (!id) return id || "暂无数据";
    const reg = /^(\d{8})(\d{8})(\d{2})$/;
    var matches = reg.exec(id);
    // console.log(matches);
    return matches[1] + "********" + matches[3];
  },
  filterNum: (num) => {
    if (!num || num == "undefined") return 0;
    if (num.toString().length >= 4) {
      return (num / 10000).toFixed(1) + "w";
    } else {
      return num || 0;
    }
  },
  numToIntW: (num) => {
    if (!num || num == "undefined") return 0;
    if (num.toString().length > 4) {
      return (num / 10000).toFixed(1) + "w";
    } else {
      return Number(num).toFixed(0) || 0;
    }
  },
  //数字取整
  numToInt: (value) => {
    if (!value) return 0;
    return Number(value).toFixed(0);
  },
  //分 =》元
  filterPrice: (value) => {
    if (!value) return value;
    // return (value / 100).toFixed(2);
    return value / 100;
  },
  //价格:分 =》 元 取整
  filterPriceCompute: (value) => {
    if (value === undefined || value === null) {
      return 0;
    } else if (value === 0) {
      return 0;
    } else {
      let remainder = value % 100;
      let result = value / 100;
      if (remainder === 0) {
        return result;
      } else {
        return result.toFixed(2);
      }
    }
  },
  //账单：分 =》 元 两位小数
  filterPrice2Decimal: (value) => {
    if (!value) {
      return "0.00";
    }
    return (value / 100).toFixed(2);
  },
  //课程报名人数
  courseSignUpNum: (value) => {
    if (value === undefined || value === null) {
      return 0;
    } else if (value === 0) {
      return 0;
    } else {
      return value;
    }
  },
  //地址-区县-显示
  filterCountyName: (value) => {
    if (value === undefined || value === null) {
      return "";
    } else if (value.length <= 3) {
      return value;
    } else {
      return `${value.slice(0, 3)}...`;
    }
  },
  filterCityName: (value) => {
    if (value == undefined || value == null) {
      return "";
    } else {
      return value;
    }
  },
  filteracademicRequirement: (value) => {
    if (value == 1) {
      return "其他";
    } else if (value == 2) {
      return "小学";
    } else if (value == 3) {
      return " 初中";
    } else if (value == 4) {
      return "普通高中 ";
    } else if (value == 5) {
      return "技工学校 ";
    } else if (value == 6) {
      return "职业高中 ";
    } else if (value == 7) {
      return "中等专科";
    } else if (value == 8) {
      return "大学专科";
    } else if (value == 9) {
      return " 大学本科";
    } else if (value == 10) {
      return "硕士研究生";
    } else if (value == 11) {
      return "博士研究生";
    }
  },
  fliterRecruit: (value) => {
    if (value == 0) {
      return "统招";
    }
    if (value == 1) {
      return "非统招";
    }
    return "----";
  },
  // 日期日历
  formatCalendar(val) {
    if (!val) return "--";
    // 扩展功能插件
    dayjs.extend(updateLocale);
    dayjs.extend(calendar);

    // 修改语言配置
    dayjs.updateLocale("zh-cn", {
      // A : 上午/下午/晚上 , dddd: 星期
      calendar: {
        lastDay: "[昨天] A h:mm",
        sameDay: "[今天] A h:mm",
        nextDay: "[明天] A h:mm",
        lastWeek: "YYYY.MM.DD A h:mm",
        nextWeek: "YYYY.MM.DD A h:mm",
        sameElse: "YYYY.MM.DD A h:mm",
      },
      relativeTime: {},
    }); // 本地化语言
    dayjs.locale("zh-cn");
    const a = dayjs(val).calendar(dayjs());

    return a;
  },
};
