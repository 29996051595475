import {
  getDepartment,
  queryFolderAndFileList,
  addFolder,
  delFile,
  countFileNum,
  getFolderList,
  searchFile,
  removeFile,
} from "@/api/fileManagerNew";
import { Toast } from "vant";
// const CONFIG_DEPART_SPACE_CODE = "1681467987134472194";
const state = {
  menuList: [
    {
      spaceCode: "",
      fileName: "公共空间",
      spaceType: "0",
      path: "公共空间",
      code: "",
      parentCode: "001",
    },
    {
      spaceCode: "",
      fileName: "部门空间",
      spaceType: "1",
      path: "部门空间",
      code: "",
      parentCode: "002",
    },

    {
      spaceCode: "",
      fileName: "个人空间",
      spaceType: "2",
      path: "个人空间",
      code: "",
      parentCode: "003",
    },
  ],
  countNumList: [], //文件统计
  curMenu: null, //当前部门/当前空间
  deparmentList: [], //部门列表
  fileList: [], //文件和文件夹集合
  curFile: null, ///当前文件夹/文件
  folderFiles: [], //选中文件夹用来删除
  moveForm: null, //移动来源
  moveTo: null, //移动到
  folderList: [], //子文件夹列表
  isSearch: false,
  searchName: "", //搜索内容
};
const mutations = {
  //设置部门
  setDepartmentList(state, payload) {
    // console.log(payload);
    state.deparmentList = payload;
    // console.log(state);
  },
  //设置当前菜单
  setCurMenu(state, payload) {
    state.curMenu = payload;
  },
  //设置当前文件夹
  setCurFile(state, payload) {
    state.curFile = payload;
  },
  //文件和文件夹
  setFileList(state, payload) {
    state.fileList = payload;
  },
  //设置文件统计数量
  setCountNum(state, payload) {
    state.countNumList = payload;
  },
  //设置选中的文件和文件夹用来删除
  setFolderFiles(state, payload) {
    state.folderFiles = payload;
  },
  //设置移动form
  setMoveForm(state, payload) {
    state.moveForm = payload;
  },
  setMoveTo(state, payload) {
    state.moveTo = payload;
  },
  //设置子文件夹
  setFolderList(state, payload) {
    state.folderList = payload;
  },
  //设置搜索
  setIsSearch(state, payload) {
    state.isSearch = payload;
  },
  //
  setSearchName(state, payload) {
    state.searchName = payload;
  },
};
const actions = {
  //获取部门
  getDepartments({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getDepartment()
        .then((res) => {
          if (res) {
            let { code, data, msg } = res;
            if (code == 200) {
              const departmentList = data.map((e) => {
                return {
                  ...e,
                  name: e.fileName,
                };
              });

              commit("setDepartmentList", departmentList);
            }
            // return res;
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(res);
        });
    });
  },
  //获取文件和文件夹
  getFolderAndFileList({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      queryFolderAndFileList(payload)
        .then((res) => {
          if (res) {
            let { code, data, msg } = res;
            if (code == 200) {
              commit("setMoveForm", null);
              commit("setFolderFiles", []);
              commit("setFileList", data);
              //获取文件数量
              dispatch("getCountFileNum");
            }
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //添加文件夹
  addFolders({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      addFolder(payload)
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 200) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //删除文件和文件夹
  delFiles({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      delFile(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //文件数量统计
  getCountFileNum({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      countFileNum(payload)
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 200) {
            commit("setCountNum", data);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //获取子文件夹列表
  getFolderLists({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      getFolderList(payload)
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 200) {
            if (state.moveForm) {
              let data1 = [];
              data.map((e) => {
                if (e.code != state.moveForm.code) {
                  data1.push(e);
                }
              });

              commit("setFolderList", data1);
            }
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //搜索文件
  searchFiles({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      searchFile(payload)
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 200) {
            commit("setFileList", data);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //移动文件
  removeFiles({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      removeFile(payload)
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 200) {
            // commit("setFolderList", data);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
