import { APP_AUTH_KEY } from "@/config/constant";

export function getToken() {
  return localStorage.getItem(APP_AUTH_KEY) || "";
}

export function setToken(payload) {
  return localStorage.setItem(APP_AUTH_KEY, payload)
}

export function removeToken() {
  return localStorage.removeItem(APP_AUTH_KEY);
}
