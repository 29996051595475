/*
 * @Description:
 * @Version: 2.0
 * @Date: 2023-07-14 16:07:20
 * @LastEditors: Fiona.xu
 * @LastEditTime: 2023-07-19 09:30:56
 */
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    name: "404",
    meta: {
      title: 404,
    },
    component: () => import("@/views/404"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/privacy/index.vue"),
    meta: {
      title: "隐私协议",
    },
  },
  {
    path: "/",
    component: () => import("@/components/topWrapper"),
    redirect: "/login",
    children: [

      {
        path: "/",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
          bgColor: "#0094EE",
          txtColor: "#fff",
          isShow:true
        },
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
          bgColor: "#0094EE",
          txtColor: "#fff",
          isShow:true
        },
      },

      {
        path: "/punish",
        name: "punish",
        component: () => import("@/views/punish"),
        meta: {
          title: "处分",
        },
      },
      {
        path: "/honor",
        name: "honor",
        component: () => import("@/views/honor"),
        meta: {
          title: "荣誉",
        },
      },
      {
        path: "/honorPunishDetail/:id",
        name: "honorPunishDetail",
        component: () => import("@/views/honorPunishDetail"),
        meta: {
          title: "荣誉详情",
        },
      },
      {
        path: "result",
        name: "result",
        component: () => import("@/views/result"),
        meta: {
          title: "结果",
        },
      },

      {
        path: "/questionList",
        name: "questionList",
        component: () => import("@/views/questionList"),
        meta: {
          title: "问卷调查",
        },
      },
      {
        path: "/question/:id",
        name: "question",
        component: () => import("@/views/question"),
        meta: {
          title: "问卷调查",
        },
      },

      {
        path: "/IntiveToJoin",
        name: "IntiveToJoin",
        component: () => import("@/views/IntiveToJoin/index.vue"),
        meta: {
          title: "邀请入职",
        },
      },
      {
        path: "/fileScan",
        name: "fileScan",
        component: () => import("@/views/fileScan"),
        meta: {
          title: "文件预览",
        },
      },
      {
        path: "/bindWechat/:time/:id",
        name: "bindWechat",
        component: () => import("@/views/bindWechat"),
        meta: {
          title: "微信账号绑定",
        },
      },
      {
        path: "/h5BindWechat/:time/:id",
        name: "bindWechat",
        component: () => import("@/views/h5BindWechat"),
        meta: {
          title: "微信账号绑定",
        },
      },
      {
        path: "/wechatlogin/:id",
        name: "wechatlogin",
        component: () => import("@/views/wechatlogin"),
        meta: {
          title: "微信登录",
        },
      },
      {
        path: "/bindWechatWithcode/:time/:id",
        name: "bindWechatWithcode",
        component: () => import("@/views/bindWechatWithcode"),
        meta: {
          title: "微信账号绑定",
        },
      },
      {
        path: "/wechatloginWithcode/:id",
        name: "wechatloginWithcode",
        component: () => import("@/views/wechatloginWithcode"),
        meta: {
          title: "微信登录",
        },
      },
      {
        path: "/h5Login",
        name: "h5Login",
        component: () => import("@/views/h5Login"),
        meta: {
          title: "微信登录",
        },
      },
      {
        path: "/fogetPass",
        name: "fogetPass",
        component: () => import("@/views/fogetPass"),
        meta: {
          title: "忘记密码",
        },
      },

      {
        path: "/changePass",
        name: "changePass",
        component: () => import("@/views/changePass/index.vue"),
        meta: {
          title: "修改密码",
        },
      },
      {
        path: "/changePhone",
        name: "changePhone",
        component: () => import("@/views/changePhone/index.vue"),
        meta: {
          title: "修改手机号",
        },
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("@/views/setting/index.vue"),
        meta: {
          title: "设置",
        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/user/index.vue"),
        meta: {
          title: "我的",
        },
      },
      {
        path: "/personalInformation",
        name: "personalInformation",
        component: () => import("@/views/personalInformation/index.vue"),
        meta: {
          title: "个人信息",
        },
      },
      {
        path: "/OAapproval",
        name: "OAapproval",
        component: () => import("@/views/OAapproval"),
        meta: {
          title: "OA审批",
        },
      },
      {
        path: "/approvalList",
        name: "approvalList",
        component: () => import("@/views/approvalList"),
        meta: {
          title: "审批列表",
        },
      },
      {
        path: "/approvalDetail",
        name: "approvalDetail",
        component: () => import("@/views/approvalDetail"),
        meta: {
          title: "审批详情",
        },
      },
      {
        path: "/approvalProcureDetail",
        name: "approvalProcureDetail",
        component: () => import("@/views/approvalProcureDetail"),
        meta: {
          title: "审批详情",
        },
      },
      {
        path: "/approvalCarDetail",
        name: "approvalCarDetail",
        component: () => import("@/views/approvalDetail/car"),
        meta: {
          title: "审批详情",
        },
      },
      {
        path: "/approvalCreat",
        name: "approvalCreat",
        component: () => import("@/views/approvalCreat"),
        meta: {
          title: "创建审核",
        },
      },
      {
        path: "/oneLevelorg",
        name: "oneLevelorg",
        component: () => import("@/views/addressList/oneLevelorg/index.vue"),
        meta: {
          title: "部门列表",
        },
      },
      {
        path: "/organize",
        name: "organize",
        component: () => import("@/views/addressList/organize/index.vue"),
        meta: {
          title: "员工通讯录",
        },
      },
      {
        path: "/personal",
        name: "personal",
        component: () => import("@/views/addressList/personal/index.vue"),
        meta: {
          title: "员工详情",
        },
      },
      {
        path: "/publishNotice",
        name: "publishNotice",
        component: () => import("@/views/notice/publishNotice/index.vue"),
        meta: {
          title: "发布通知",
        },
      },
      {
        path: "/noticeDetail",
        name: "noticeDetail",
        component: () => import("@/views/notice/detail/index.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/msgList",
        name: "msgList",
        component: () => import("@/views/message/list/index.vue"),
        meta: {
          title: "消息列表",
        },
      },
      {
        path: "/rulesSystem",
        name: "rulesSystem",
        component: () => import("@/views/rulesSystem/list/index.vue"),
        meta: {
          title: "规章制度",
        },
      },
      {
        path: "/rulesSystemDetail",
        name: "rulesSystemDetail",
        component: () => import("@/views/rulesSystem/detail/index.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/myPublish",
        name: "myPublish",
        component: () => import("@/views/myPublish/index.vue"),
        meta: {
          title: "我的发布",
        },
      },
      {
        path: "/publishDetail",
        name: "publishDetail",
        component: () => import("@/views/myPublish/detail.vue"),
        meta: {
          title: "发布详情",
        },
      },
      {
        path: "/afficheList",
        name: "afficheList",
        component: () => import("@/views/affiche/list/index.vue"),
        meta: {
          title: "通知公告列表",
        },
      },
      {
        path: "/afficheDetail",
        name: "afficheDetail",
        component: () => import("@/views/affiche/detail/index.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/salaryList",
        name: "salaryList",
        component: () => import("@/views/salary/list/index.vue"),
        meta: {
          title: "工资条",
        },
      },
      {
        path: "/salaryDetail",
        name: "salaryDetail",
        component: () => import("@/views/salary/detail/index.vue"),
        meta: {
          title: "工资详情",
        },
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/about/index.vue"),
        meta: {
          title: "关于",
        },
      },
      {
        path: "/spaceList",
        name: "spaceList",
        component: () => import("@/views/fileManager/spaceList/index.vue"),
        meta: {
          title: "知识库",
        },
      },
      {
        path: "/fileFolder",
        name: "fileFolder",
        component: () => import("@/views/fileManager/fileFolder/index.vue"),
        meta: {
          title: "知识库",
        },
      },
      {
        path: "/clockIn",
        name: "clockIn",
        component: () => import("@/components/clockInTabBar/index.vue"),
        children: [
          {
            path: "",
            name: "clockInHome",
            component: () => import("@/views/clockIn/index.vue"),
            meta: {
              title: "打卡",
            },
          },
          {
            path: "statistics",
            name: "statistics",
            component: () => import("@/views/statistics/index.vue"),
            meta: {
              title: "统计",
            },
          },
        ],
      },
      {
        path: "/clockRole",
        name: "clockRole",
        component: () => import("@/views/clockRole/index.vue"),
        meta: {
          title: "打卡规则",
        },
      },
      {
        path: "/AMap",
        name: "AMap",
        component: () => import("@/views/AMap/index.vue"),
        meta: {
          title: "高德地图",
        },
      },
      {
        path: "/spaceListNew",
        name: "spaceListNew",
        component: () => import("@/views/fileManagerNew/spaceList/index.vue"),
        meta: {
          title: "知识库1",
        },
      },
      {
        path: "/fileFolderNew",
        name: "fileFolderNew",
        component: () => import("@/views/fileManagerNew/fileFolder/index.vue"),
        meta: {
          title: "知识库1",
        },
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("@/views/feedback/index.vue"),
        meta: {
          title: "反馈",
        },
      },
      {
        path: "/feedbackList",
        name: "feedbackList",
        component: () => import("@/views/feedbackList/index.vue"),
        meta: {
          title: "反馈列表",
        },
      },
      {
        path: "/feedbackDetail",
        name: "feedbackDetail",
        component: () => import("@/views/feedbackDetail/index.vue"),
        meta: {
          title: "反馈详情",
        },
      },
      {
        path: "/smartMeetingRoom",
        name: "smartMeetingRoom",
        component: () => import("@/views/smartMeetingRoom/index.vue"),
        meta: {
          title: "智能会议室",
        },
      },
      {
        path: "/meetingRoomManager",
        name: "meetingRoomManager",
        component: () =>
          import("@/views/smartMeetingRoom/meetingRoomManager/index.vue"),
        meta: {
          title: "会议室管理",
        },
      },
      {
        path: "/addMeetingRoom",
        name: "addMeetingRoom",
        component: () =>
          import("@/views/smartMeetingRoom/meetingRoomManager/addMeetingRoom.vue"),
        meta: {
          title: "会议室管理",
        },
      },
      {
        path: "/orderedmeetingRoom",
        name: "orderedmeetingRoom",
        component: () =>
          import("@/views/smartMeetingRoom/orderedmeetingRoom/index.vue"),
        meta: {
          title: "已预约会议室",
        },
      },
      {
        path: "/orderMeetingRoom",
        name: "orderMeetingRoom",
        component: () =>
          import("@/views/smartMeetingRoom/orderMeetingRoom/index.vue"),
        meta: {
          title: "预约会议室",
        },
      },
      {
        path: "/assetsList",
        name: "assetsList",
        component: () => import("@/views/fixedAssets/assetsList/index.vue"),
        meta: {
          title: "固定资产列表",
        },
      },

      {
        path: "/assetsDetail",
        name: "assetsDetail",
        component: () => import("@/views/fixedAssets/assetsDetail/index.vue"),
        meta: {
          title: "固定资产详情",
        },
      },
      {
        path: "/assetsManList",
        name: "assetsManList",
        component: () => import("@/views/fixedAssetsManager/assetsList/index.vue"),
        meta: {
          title: "固定资产管理",
        },
      },
      {
        path: "/assetsManDetail",
        name: "assetsManDetail",
        component: () =>
          import("@/views/fixedAssetsManager/assetsDetail/index.vue"),
        meta: {
          title: "固定资产详情",
        },
      },
      {
        path: "/addAssetsMan",
        name: "addAssetsMan",
        component: () => import("@/views/fixedAssetsManager/addAssets/index.vue"),
        meta: {
          title: "添加固定资产",
        },
      },

      {
        path: "/carMgr",
        name: "carMgr",
        component: () => import("@/views/car/carMgr/index.vue"),
        meta: {
          title: "车辆管理",
        },
      },
      {
        path: "/carDetail",
        name: "carDetail",
        component: () => import("@/views/car/carDetail/index.vue"),
        meta: {
          title: "车辆详情",
        },
      },

      {
        path: "/applyCar",
        name: "applyCar",
        component: () => import("@/views/car/applyCar/index.vue"),
        meta: {
          title: "申请用车",
        },
      },
      {
        path: "/carBack",
        name: "carBack",
        component: () => import("@/views/car/carBack/index.vue"),
        meta: {
          title: "还车登记",
        },
      },
      {
        path: "/useList",
        name: "useList",
        component: () => import("@/views/car/useList/index.vue"),
        meta: {
          title: "用车记录",
        },
      },
      {
        path: "/backList",
        name: "backList",
        component: () => import("@/views/car/backList/index.vue"),
        meta: {
          title: "还车记录",
        },
      },
      {
        path: "/applyDetail",
        name: "applyDetail",
        component: () => import("@/views/car/applyDetail/index.vue"),
        meta: {
          title: "用车详情",
        },
      },
      {
        path: "/backDetail",
        name: "backDetail",
        component: () => import("@/views/car/backDetail/index.vue"),
        meta: {
          title: "用车详情",
        },
      },
      {
        path: "/washCar",
        name: "washCar",
        component: () => import("@/views/car/washCar/index.vue"),
        meta: {
          title: "添加洗车",
        },
      },

      {
        path: "/suppliesHome",
        name: "suppliesHome",
        component: () => import("@/views/officeSupplies/suppliesHome/index.vue"),
        meta: {
          title: "办公用品",
        },
      },
      {
        path: "/suppliesList",
        name: "suppliesList",
        component: () => import("@/views/officeSupplies/suppliesList/index.vue"),
        meta: {
          title: "办公用品管理",
        },
      },
      {
        path: "/suppliesHistory",
        name: "suppliesHistory",
        component: () => import("@/views/officeSupplies/suppliesHistory/index.vue"),
        meta: {
          title: "领取历史",
        },
      },
      {
        path: "/mySupplies",
        name: "mySupplies",
        component: () => import("@/views/officeSupplies/mySupplies/index.vue"),
        meta: {
          title: "我的申领",
        },
      },
      {
        path: "/addSupplies",
        name: "addSupplies",
        component: () => import("@/views/officeSupplies/addSupplies/index.vue"),
        meta: {
          title: "新增办公用品",
        },
      },
      {
        path: "/suppliesDetail",
        name: "suppliesDetail",
        component: () => import("@/views/officeSupplies/suppliesDetail/index.vue"),
        meta: {
          title: "办公用品详情",
        },
      },
      {
        path: "/mySuppliesDetail",
        name: "mySuppliesDetail",
        component: () =>
          import("@/views/officeSupplies/mySuppliesDetail/index.vue"),
        meta: {
          title: "申请详情",
        },
      },
      {
        path: "/EmailHome",
        name: "EmailHome",
        component: () => import("@/views/Email/home/index.vue"),
        meta: {
          title: "邮箱首页",
        },
      },
      {
        path: "/EmailSetting",
        name: "EmailSetting",
        component: () => import("@/views/Email/setting/index.vue"),
        meta: {
          title: "邮箱设置",
        },
      },
      {
        path: "/EmailAddressBook",
        name: "EmailAddressBook",
        component: () => import("@/views/Email/addressBook/index.vue"),
        meta: {
          title: "通讯录",
        },
      },
      {
        path: "/addEmailGroup",
        name: "addEmailGroup",
        component: () => import("@/views/Email/addressBook/group.vue"),
        meta: {
          title: "新建分组",
        },
      },
      {
        path: "/addEmailContact",
        name: "addEmailContact",
        component: () => import("@/views/Email/addressBook/add.vue"),
        meta: {
          title: "联系人",
        },
      },
      {
        path: "/EmailContactDetail/:id",
        name: "EmailContactDetail",
        component: () => import("@/views/Email/addressBook/detail.vue"),
        meta: {
          title: "联系人详情",
        },
      },
      {
        path: "/EmailSend",
        name: "EmailSend",
        component: () => import("@/views/Email/send/index.vue"),
        meta: {
          title: "写邮件",
        },
      },
      {
        path: "/EmailHarvest",
        name: "EmailHarvest",
        component: () => import("@/views/Email/harvest/index.vue"),
        meta: {
          title: "收件箱",
        },
      },
      {
        path: "/harvestDetail",
        name: "harvestDetail",
        component: () => import("@/views/Email/harvestDetail/index.vue"),
        meta: {
          title: "收件详情",
        },
      },
      {
        path: "/EmailDrafts",
        name: "EmailDrafts",
        component: () => import("@/views/Email/drafts/index.vue"),
        meta: {
          title: "草稿箱",
        },
      },
      {
        path: "/editDrafts",
        name: "editDrafts",
        component: () => import("@/views/Email/editDrafts/index.vue"),
        meta: {
          title: "编辑草稿",
        },
      },
      {
        path: "/editDrafts",
        name: "editDrafts",
        component: () => import("@/views/Email/editDrafts/index.vue"),
        meta: {
          title: "草稿箱",
        },
      },
      {
        path: "/EmailAsterisk",
        name: "EmailAsterisk",
        component: () => import("@/views/Email/asterisk/index.vue"),
        meta: {
          title: "星标邮件",
        },
      },

      {
        path: "/EmailOutbox",
        name: "EmailOutbox",
        component: () => import("@/views/Email/outbox/index.vue"),
        meta: {
          title: "发件箱",
        },
      },
      {
        path: "/outboxDetail",
        name: "outboxDetail",
        component: () => import("@/views/Email/outboxDetail/index.vue"),
        meta: {
          title: "发件箱详情",
        },
      },
      {
        path: "/againEmail",
        name: "againEmail",
        component: () => import("@/views/Email/againEmail/index.vue"),
        meta: {
          title: "再来一封",
        },
      },
      {
        path: "/editEmail",
        name: "editEmail",
        component: () => import("@/views/Email/editEmail/index.vue"),
        meta: {
          title: "再次编辑",
        },
      },
      {
        path: "/EmailRecycleBin",
        name: "EmailRecycleBin",
        component: () => import("@/views/Email/recycleBin/index.vue"),
        meta: {
          title: "回收站",
        },
      },
      {
        path: "/recycleBinDetail",
        name: "recycleBinDetail",
        component: () => import("@/views/Email/recycleBinDetail/index.vue"),
        meta: {
          title: "回收站详情",
        },
      },

      {
        path: "/EmailReplyEmail",
        name: "EmailReplyEmail",
        component: () => import("@/views/Email/replyEmail/index.vue"),
        meta: {
          title: "回复邮件",
        },
      },
      {
        path: "/forwardEmail",
        name: "forwardEmail",
        component: () => import("@/views/Email/forwardEmail/index.vue"),
        meta: {
          title: "转发邮件",
        },
      },


      {
        path: "/register",
        name: "register",
        component: () => import("@/views/register/index.vue"),
        meta: {
          title: "注册账户",
        },
      },
      {
        path: "/delCount",
        name: "delCount",
        component: () => import("@/views/delCount/index.vue"),
        meta: {
          title: "注销账号",
        },
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
