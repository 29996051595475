import {
  queryFolderAndFileList,
  getDepartment,
  delFile,
  getFolderList,
  removeFile,
  addFolder,
} from "@/api/fileManager/index.js";
const store = {
  namespaced: true,
  state: {
    menuList: [], //菜单列表
    departmentList: [],
    viewTabs: [], //导航
    foldersFiles: [], //文件和文件夹
    folders: [], //可移动文件夹
    moveViews: [], //可移动文件夹的tab
    curFile: null, //当前文件
  },
  mutations: {
    setMenuList(state, payload) {
      state.menuList = payload;
    },
    setDepartmentList(state, payload) {
      state.departmentList = payload;
    },
    setViewTabs(state, payload) {
      // console.log("setViewTabs", payload);
      state.viewTabs.push(payload);
      // console.log("viewTabs---", state.viewTabs);
    },
    delViewTabs(state, payload) {
      state.viewTabs = state.viewTabs.splice(0, payload);
    },
    resetViewTabs(state) {
      state.viewTabs = [];
    },
    setFolderFiles(state, payload) {
      state.foldersFiles = payload;
      // console.log(state.foldersFiles, "setFolderFiles");
    },
    setFolders(state, payload) {
      state.folders = payload;
      // console.log(state.folders, "state.folders");
    },
    setMoveViews(state, payload) {
      state.moveViews.push(payload);
    },
    delMoveViews(state, payload) {
      state.moveViews = state.moveViews.splice(0, payload);
    },
    resetMoveViews(state, payload) {
      state.moveViews = [];
    },
    setCurFile(state, payload) {
      state.curFile = payload;
    },
  },
  getters: {},
  actions: {
    // 一级菜单
    getMenu({ commit, dispatch }) {
      // console.log("一级菜单");
      queryFolderAndFileList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            const list = data.folderVOList.map((e) => {
              return {
                folderCode: e.folderCode,
                folderName: e.folderName,
                folderType: e.folderType,
                isD: e.folderCode == "1681467987134472194" ? true : false, //部门空间
              };
            });
            commit("setMenuList", list);
          }
        }
        return res;
      });
    },
    //获取员工部门
    getDepartments({ commit }) {
      // console.log("获取员工部门");
      getDepartment()
        .then((res) => {
          if (res) {
            let { data, code } = res;
            if (code == 200) {
              const departmentList = data.map((e) => {
                return {
                  folderCode: "1681467987134472194",
                  folderName: e.departmentName,
                  folderType: 2, //部门空间
                  isD: true, //是否部门
                  departmentCode: e.departmentCode,
                  name: e.departmentName,
                };
              });
              commit("setDepartmentList", departmentList);
            }
            return res;
          }
        })
        .catch((e) => {
          return null;
        });
    },
    // 获取文件和文件夹
    getFoldersFiles({ commit, dispatch }, payload) {
      commit("setFolderFiles", []);
      let a = null,
        b = null;
      return new Promise((resolve, reject) => {
        queryFolderAndFileList(payload)
          .then((res) => {
            if (res) {
              // console.log(res, "res=======");
              let { data, code } = res;
              if (code == 200) {
                if (data.folderVOList) {
                  b = data.folderVOList.map((e) => {
                    return {
                      folderCode: e.folderCode,
                      folderName: e.folderName,
                      folderType: e.folderType,
                      fileCode: "",
                      createTime: e.createTime,
                      createUser: "",
                      parentCode: e.parentCode,
                      isFolder: 1, //文件夹
                      departmentCode: e.departmentCode ? e.departmentCode : "",
                      checked: false,
                      updateTime: e.updateTime || "",
                      createUserName: e.createUserName || "",
                    };
                  });
                }
                if (data.fileVOList) {
                  a = data.fileVOList.map((e) => {
                    return {
                      ...e,
                      folderCode: e.fileCode,
                      folderName: e.fileName,
                      folderType: e.fileType,
                      fileCode: e.fileCode,
                      createTime: e.createTime,
                      createUser: "",
                      parentCode: e.folderCode,
                      isFolder: 0, //文件
                      departmentCode: e.departmentCode ? e.departmentCode : "",
                      checked: false,
                      updateTime: e.updateTime || "",
                      createUserName: e.createUserName || "",
                    };
                  });
                }
                if (a && b) {
                  commit("setFolderFiles", [...b, ...a]);
                }
                if (!a && b) {
                  commit("setFolderFiles", [...b]);
                }
                if (!b && a) {
                  commit("setFolderFiles", [...a]);
                }
                resolve(res);
              } else {
                reject(res);
              }
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    //删除文件
    delFileFol({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        delFile(payload)
          .then((res) => {
            let { code, data, msg } = res;
            if (code == 200) {
              let arr = JSON.parse(JSON.stringify(state.viewTabs));
              let obj = arr.pop();
              // console.log(obj, "obj======");
              if (obj) {
                dispatch("getFoldersFiles", {
                  departmentCode: obj.departmentCode || "",
                  folderCode: obj.code,
                  folderType: obj.type,
                });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    //获取文件夹
    getFolderListAction({ commit, dispatch }, payload) {
      // console.log("获取文件夹");
      return new Promise((resolve, reject) => {
        getFolderList(payload)
          .then((res) => {
            let { code, data, msg } = res;
            if (code == 200) {
              commit("setFolders", data);
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    //移动文件夹
    moveFileToFolder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        removeFile(payload)
          .then((res) => {
            let { code, data, msg } = res;
            if (code == 200) {
              commit("setCurFile", null);
              commit("resetMoveViews");
              commit("setFolders", []);

              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    //添加文件夹
    addFolderAction({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        addFolder(payload)
          .then((res) => {
            if (res) {
              let { msg, code } = res;
              if (code == 200) {
                resolve(res);
              } else {
                reject(res);
              }
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};

export default store;
