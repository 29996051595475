import request from "@/utils/request";
import { APP_USER_PC_SOURCE } from "@/config/constant";

//微信扫码登录
export function loginWithWechat(data) {
  return request({
    url: "/account/loginWithWechat",
    method: "post",
    data: data,
    headers:{
      "User-Source":APP_USER_PC_SOURCE
    }
  });
}


//账号绑定微信
export function accountWechatBind(data) {
  return request({
    url: "/account/accountWechatBind",
    method: "post",
    data: data,
    headers:{
      "User-Source":APP_USER_PC_SOURCE
    }
  });
}

// 根据当前登录用户，获取绑定微信二维码地址
export function queryQrcodeByAccount(params) {
  return request({
    url: "/account/queryQrcodeByAccount",
    method: "get",
    params: params,
    headers:{
      "User-Source":APP_USER_PC_SOURCE,
    }
  });
}

// 获取扫码状态
export function queryScanType(params) {
  return request({
    url: "/account/queryScanType",
    method: "get",
    params: params,
    headers:{
      "User-Source":APP_USER_PC_SOURCE,
    }
  });
}
// 微信授权登录
export function h5Login(params) {
  return request({
    url: "/account/h5Login",
    method: "get",
    params: params,
  });
}


// 获取微信openId
export function getWXOpenId(params) {
  return request({
    url: `/account/getWXOpenId/${params.code}`,
    method: "get",
  });
}
