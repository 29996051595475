import axios from "axios";
import Cookies from "js-cookie";

export function getRefreshToken() {
  return axios
    .get(`${process.env.VUE_APP_BASE_URL}/account/refreshToken`, {
      headers: {
        Authorization: localStorage.getItem("Authorization_OA_H5"),
        "User-Source": "APP_OA",
      },
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.resolve(e);
    });
}
export function isRefreshTokenExpired() {}
