import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import defaultImg from "@/assets/default.png";
import Calendar from "vue-mobile-calendar";
import dayjs from "dayjs";
import filters from "./common/filters";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VConsole from "@/utils/vconsole";
import { Toast, Dialog } from "vant";
import "@/assets/iconapprove/iconfont.css"; // approveiconfont
import "@/assets/iconapprove/iconfont.js";
import "vant/lib/index.less";
import "@/styles/index.scss";
// 引入utils的方法
import * as utils from "@/utils/format.js";
Vue.prototype.$utils = utils;

//注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$d = dayjs;
Vue.use(Calendar);
Vue.use(Vant);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(ElementUI, {
  size: "mini",
});
Vue.use(VueQuillEditor);

if (process.env.NODE_ENV === "development") {
  new VConsole();
}

Vue.config.productionTip = false;
Vue.prototype.$defaultImg = defaultImg; //默认图片

function setHtmlFontSize() {
  const htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const htmlDom = document.getElementsByTagName("html")[0];
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
}
setHtmlFontSize();
window.addEventListener("resize", setHtmlFontSize);
window.entryUrl = location.href.split("#")[0];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");







// android点击手机物理返回键退出app
document.addEventListener('plusready', function () {







  console.log("plusready")
  var first = null;
  var webview = plus.webview.currentWebview();
  plus.key.addEventListener('backbutton', function () {
    webview.canBack(function (e) {
      if (e.canBack) {
        webview.back(); //这里不建议修改自己跳转的路径
      } else {
        //首次按键，提示‘再按一次退出应用’
        if (!first) {
          first = new Date().getTime(); //获取第一次点击的时间戳
          plus.nativeUI.toast("再按一次退出应用", {
            duration: 'short'
          }); //通过H5+ API 调用Android 上的toast 提示框
          setTimeout(function () {
            first = null;
          }, 1000);
        } else {
          if (new Date().getTime() - first < 1000) { //获取第二次点击的时间戳, 两次之差 小于 1000ms 说明1s点击了两次,
            plus.runtime.quit(); //退出应用
          }
        }
      }
    })
  });


  // plus.geolocation.getCurrentPosition(
  //   function (p) {
  //     // p为获取成功的定位数据
  //     console.log('纬度:' + p.coords.latitude + '\n经度:' + p.coords.longitude + '\n海拔:' + p.coords.altitude)
  //   }, function (err) {
  //     //失败回调
  //     console.log('Gelocation Error: code - ' + err.code + '; message - ' + err.message);
  //   });

  let info = plus.push.getClientInfo();
  console.log("ClientInfo", JSON.stringify(info));

  //收到透传消息  
  //只有APP在线时，才会触发receive事件，透传消息不会触发系统消息,需要创建本地消息  
  plus.push.addEventListener("receive", function (msg) {
    console.log("(receive):" + JSON.stringify(msg));
    console.log("(click):" + JSON.stringify(msg));
    if(msg.payload.path){
      router.push(msg.payload.path)
    }
  }, false);

  //消息点击事件  
  //【APP在线】，收到透传消息通过，不会提醒至通知栏目，需要发送本地消息，再进行点击触发的点击事件。  
  //【APP离线】，收到离线透传消息，必须通过Java后台的Intent字符串携带payload，且符合格式才能触发click事件，格式不符合不会触发。  
  plus.push.addEventListener("click", function (msg) {
    console.log("(click):" + JSON.stringify(msg));
    if(msg.payload.path){
      router.push(msg.payload.path)
    }
  }, false);
});