<template>
  <div id="app">
    <router-view v-if="refreshed" :k="token" />
  </div>
</template>
<script>
import _ from "lodash";
import Cookies from "js-cookie";
import { getRefreshToken } from "@/utils/refreshToken.js";
import { getToken, removeToken } from "@/utils/auth.js";
export default {
  data() {
    return {
      refreshed: false,
    };
  },
  created() {},
  async mounted() {
    await this.checkToken();
  },
  computed: {
    token() {
      if (
        getToken() === "undefined" ||
        !getToken() ||
        getToken() === undefined
      ) {
        return "";
      }
      return getToken();
    },
  },
  methods: {
    checkToken() {
      if (
        getToken() === "undefined" ||
        !getToken() ||
        getToken() === undefined
      ) {
        this.refreshed = true;
      } else {
        getRefreshToken().then((res) => {
          if (res.code == 200) {
            removeToken();
            localStorage.setItem("Authorization_OA_H5", res.data);
            // console.log("---获取token", getToken());
            this.refreshed = true;
            return;
          } else {
            localStorage.removeItem("Authorization_OA_H5");
            localStorage.removeItem("staffCode_H5");
            localStorage.removeItem("userName_H5");
            localStorage.removeItem("openId_H5");
            this.refreshed = true;
            this.$router.replace("/login");
          }
        });
      }
    },
  },
};
</script>
<style lang="scss"></style>
