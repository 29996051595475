import request from "@/utils/request";
import Cookies from "js-cookie";
/*
 * 获取知识库文件夹及文件列表
departmentCode	所属部门	query	false	
string
folderCode	文件夹/空间编码，不传查主空间	query	false	
string
folderType 1-公共空间 2-部门空间 3-个人空间
 */
export function queryFolderAndFileList(params) {
  return request({
    url: "/knowledge-file/queryFolderAndFileList",
    method: "GET",
    params,
  });
}
/*
获取员工所在部门
*/
// export function getDepartment(params) {
//   return request({
//     url: "/staff/getDepartment",
//     method: "GET",
//     params,
//   });
// }
/*
获取员工所在部门
*/
export function getDepartment(params) {
  return request({
    url: "/staff/getDepartment",
    method: "GET",
    params,
  });
}
/*
添加文件夹
*/
export function addFolder(data) {
  return request({
    url: "/knowledge-file/addFolder",
    method: "POST",
    data,
  });
}
/*
添加文件
*/
export function addFile(data) {
  return request({
    url: "/knowledge-file/addFile",
    method: "POST",
    data,
    headers: {
      // Authorization: localStorage.getItem("Authorization_OA_H5"),
      Authorization:localStorage.getItem("Authorization_OA_H5"),
      "User-Source": "APP_OA",
    },
  });
}
/*
删除文件和文件夹
*/
export function delFile(data) {
  return request({
    url: `/knowledge-file/delFile`,
    method: "POST",
    data,
  });
}
/*
文件数量统计
*/
export function countFileNum(params) {
  return request({
    url: `/knowledge-file/countFileNum`,
    method: "GET",
    params,
  });
}

/*
文件下载
*/
export function downLoadFile(data) {
  return request({
    url: `/knowledge-file/downLoadFile`,
    method: "POST",
    responseType: "blob",
    data,
  });
}
/*
移动文件
*/
export function removeFile(params) {
  return request({
    url: `/knowledge-file/removeFile`,
    method: "GET",
    params,
  });
}
/*
获取文件夹列表
*/
export function getFolderList(params) {
  return request({
    url: `/knowledge-file/getFolderList`,
    method: "GET",
    params,
  });
}
