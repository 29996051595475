import request from "@/utils/request";
import Cookies from "js-cookie";
/*
 * 获取员工所在部门

 */
export function getDepartment(params) {
  return request({
    url: "/knowledge/space/getDepartment",
    method: "GET",
    params,
  });
}
/*
 * 获取知识库文件夹及文件列表

 */
export function queryFolderAndFileList(params) {
  return request({
    url: "/knowledge/space/queryList",
    method: "GET",
    params,
  });
}

/*
新增文件夹
*/
export function addFolder(data) {
  return request({
    url: `/knowledge/space/addFolder`,
    method: "POST",
    data,
  });
}
/*
删除文件
*/
export function delFile(data) {
  return request({
    url: `/knowledge/space/delFile`,
    method: "POST",
    data,
  });
}
/*添加文件
 */
export function addFile(data) {
  return request({
    url: `/knowledge/space/addFile`,
    method: "POST",
    data,
  });
}
/*
 * 文件数量统计

 */
export function countFileNum(params) {
  return request({
    url: "/knowledge/space/countFileNum",
    method: "GET",
    params,
  });
}
/*下载文件
 */
export function downLoadFile(data) {
  return request({
    url: `/knowledge/space/downLoadFile`,
    method: "POST",
    responseType: "blob",
    data,
  });
}
/*移动文件
 */
export function removeFile(params) {
  return request({
    url: `/knowledge/space/removeFile`,
    method: "GET",
    params,
  });
}

/*获取子文件夹列表
 */
export function getFolderList(params) {
  return request({
    url: `/knowledge/space/getFolderList`,
    method: "GET",
    params,
  });
}
/*搜索文件
 */
export function searchFile(params) {
  return request({
    url: `/knowledge/space/searchFile`,
    method: "GET",
    params,
  });
}
