import Vue from "vue";
import Vuex from "vuex";
import global from "./global";
import login from "./login";
import fileManager from "./fileManager";
import fileManagerNew from "./fileManagerNew";
import orderMeetingRoom from "./orderMeetingRoom";
import question from "./question";
import email from "./email";

Vue.use(Vuex);
const store = new Vuex.Store({
  // modules,
  modules: {
    global,
    login,
    fileManager,
    fileManagerNew,
    orderMeetingRoom,
    question,
    email,
  },
});

export default store;
