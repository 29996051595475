import { removeToken, setToken, getToken } from "@/utils/auth";

const store = {
  namespaced: true,
  state: {
    isLogin: false, //是否登录
    isAuth: false, //是否实名
    token: "", //token
    userInfo: null, //用户信息
    isWxMiniApp: false, //是否是小程序环境
  },
  mutations: {
    setToken(state, payload) {
      setToken(payload);
      state.token = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setIsLogin(state, payload) {
      state.isLogin = payload;
    },
    setIsAuth(state, payload) {
      state.isAuth = payload;
    },
    setIsWxMiniApp(state, payload) {
      state.isWxMiniApp = payload;
    },
  },
  getters: {},
  actions: {
    //token 前端登录
    loginByToken({ commit, state, dispatch }, payload) {
      commit("setToken", payload);
      commit("setIsLogin", true);
      // alert(JSON.stringify(res.data))
      //获取用户信息
      // dispatch('getUserInfo');
    },
    // 系统用户退出
    logout({ commit }, payload) {
      commit("setToken", "");
      removeToken();
      commit("setIsLogin", false);
      commit("setIsAuth", false);
      commit("setUserInfo", null);
    },
    // 检查是否是小程序环境
    getMiniAppEnv({ commit }, payload) {
      if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => {
            const isMiniApp = window.__wxjs_environment === "miniprogram";
            commit("setIsWxMiniApp", isMiniApp);
          },
          false
        );
      } else {
        const isMiniApp = window.__wxjs_environment === "miniprogram";
        commit("setIsWxMiniApp", isMiniApp);
      }
    },

    //获取用户信息
    // getUserInfo({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     Toast.loading({
    //       duration: 0,
    //       message: '加载中...',
    //       forbidClick: true,
    //     });
    //     getUserInfo().then(res => {
    //       commit('setUserInfo', res.data);
    //       commit('setIsAuth', true);
    //       Toast.clear();
    //       resolve()
    //     }).catch(err => {
    //       Toast.clear();
    //     })
    //   })
    // },
    //实名认证
    // postRealNameAuthen({ commit, dispatch }, payload) {
    //   return new Promise((resolve, reject) => {
    //     postRealNameAuthen(payload).then(res => {
    //       Toast('实名认证成功')
    //       dispatch('loginByToken', res.data.token);
    //       resolve()
    //     })
    //   })
    // }
  },
};

export default store;
