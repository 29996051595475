import {
  getMyEmailInfo,
  groupAddressList,
  getAllContactUser,
} from "@/api/email/index.js";
const state = {
  myEmailInfo: null, //个人邮箱信息
  groupAddress: null, //分组树形人员
  allAddress: null, //全部联系人员
};
const mutations = {
  setEmailInfo(state, payload) {
    state.myEmailInfo = payload;
  },
  setGroupAddress(state, payload) {
    state.groupAddress = payload;
  },
  setAllAddress(state, payload) {
    state.allAddress = payload;
  },
};
const actions = {
  async getEmailInfo({ commit, dispatch }, payload) {
    const res = await getMyEmailInfo();
    let { data, code } = res;
    if (code == 200) {
      commit("setEmailInfo", data);
    }
    return res;
  },
  async getGroupContactList({ commit, dispatch }, payload) {
    const res = await groupAddressList();
    if (res) {
      let { data, code } = res;
      if (code == 200 && data) {
        const a = data.map((e) => {
          return {
            label: e.name,
            value: e.groupCode,
            disabled: true,
            children: e.addressBooks.map((m) => {
              return {
                label: m.userName,
                value: m.email,
              };
            }),
          };
        });
        commit("setGroupAddress", a);
      }
    }
    return res;
  },
  async getAllUsers({ commit }, payload) {
    const res = await getAllContactUser();
    if (res) {
      let { data, success } = res;
      if (success && data) {
        const arr = data.map((e) => {
          return {
            label: e.userName,
            value: e.email,
          };
        });
        if (arr) {
          commit("setAllAddress", arr);
        }
      }
      return res;
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
