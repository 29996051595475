import request from "@/utils/request";

/*
 * 
   1.添加通讯录分组
 */
export function addAddressBookGroup(data) {
  return request({
    url: "/email/addAddressBookGroup",
    method: "POST",
    data,
  });
}
/* 2.添加联系人 */
export function addContactUser(data) {
  return request({
    url: "/email/addContactUser",
    method: "POST",
    data,
  });
}
/* 3.取消标星 */
export function cancleStar(params) {
  return request({
    url: "/email/cancleStar",
    method: "GET",
    params,
  });
}
/* 4.删除通讯录分组 */
export function delAddressBookGroup(params) {
  return request({
    url: "/email/delAddressBookGroup",
    method: "GET",
    params,
  });
}

/* 5.删除联系人 */
export function delContactUser(params) {
  return request({
    url: "/email/delContactUser",
    method: "GET",
    params,
  });
}
/* 6.编辑联系人 */
export function editContactUser(data) {
  return request({
    url: "/email/editContactUser",
    method: "POST",
    data,
  });
}
/* 7.编辑通讯录分组 */
export function eidtAddressBookGroup(data) {
  return request({
    url: "/email/eidtAddressBookGroup",
    method: "POST",
    data,
  });
}
/* 8.分页获取通讯录列表 */
export function getAddressBookPageList(params) {
  return request({
    url: "/email/getAddressBookPageList",
    method: "GET",
    params,
  });
}

/* 9.获取所有联系人 */
export function getAllContactUser(params) {
  return request({
    url: "/email/getAllContactUser",
    method: "GET",
    params,
  });
}
/* 10.获取联系人详情 */
export function getContactUserInfo(params) {
  return request({
    url: "/email/getContactUserInfo",
    method: "GET",
    params,
  });
}
/* 11.获取个人邮箱信息 */
export function getMyEmailInfo(params) {
  return request({
    url: "/email/getMyEmailInfo",
    method: "GET",
    params,
  });
}
/* 12.分页获取收件箱列表 */
export function getRecievePageList(params) {
  return request({
    url: "/email/getRecievePageList",
    method: "GET",
    params,
  });
}
/* 13.分页获取发件箱列表 */
export function getSendEmailPageList(params) {
  return request({
    url: "/email/getSendEmailPageList",
    method: "GET",
    params,
  });
}
/* 14.分页获取邮件标星列表 */
export function getStartPageList(params) {
  return request({
    url: "/email/getStartPageList",
    method: "GET",
    params,
  });
}
/* 15.获取通讯录所有分组下联系人 */
export function groupAddressList(params) {
  return request({
    url: "/email/groupAddressList",
    method: "GET",
    params,
  });
}
/* 16.获取通讯录分组 */
export function groupList(params) {
  return request({
    url: "/email/groupList",
    method: "GET",
    params,
  });
}
/* 17.邮件标星 */
export function handleStar(params) {
  return request({
    url: "/email/handleStar",
    method: "GET",
    params,
  });
}
/* 18.发送邮件 */
export function sendEmail(data) {
  return request({
    url: "/email/sendEmail",
    method: "POST",
    data,
  });
}
/* 19.设置邮箱 */
export function setEmail(params) {
  return request({
    url: "/email/setEmail",
    method: "GET",
    params,
  });
}
/* 20.通讯录联系人设置分组 */
export function setGroupByAddress(data) {
  return request({
    url: "/email/setGroupByAddress",
    method: "POST",
    data,
  });
}
/* 21.单文件分片上传 */
export function singleFilePartUpload(data) {
  return request({
    url: "/email/singleFilePartUpload",
    method: "POST",
    data,
  });
}
/* 22.同步邮箱 */
export function synchronousEmail(params) {
  return request({
    url: "/email/synchronousEmail",
    method: "GET",
    params,
  });
}
/* 23.恢复邮件 */
export function restoreEmail(params) {
  return request({
    url: "/email/restoreEmail",
    method: "GET",
    params,
  });
}
/* 24.放入回收站 */
export function handleRecycle(data) {
  return request({
    url: "/email/handleRecycle",
    method: "POST",
    data,
  });
}
/* 25.邮件详情 */
export function emailInfo(params) {
  return request({
    url: "/email/emailInfo",
    method: "GET",
    params,
  });
}
/* 26.回收站列表 */
export function getRecycleList(params) {
  return request({
    url: "/email/getRecycleList",
    method: "GET",
    params,
  });
}
/* 27.标记邮件已读或未读 */
export function tagEmail(params) {
  return request({
    url: "/email/tagEmail",
    method: "GET",
    params,
  });
}
/* 28.撤回邮件 */
export function canclelEmail(params) {
  return request({
    url: "/email/canclelEmail",
    method: "GET",
    params,
  });
}
/* 29.批量删除邮件 */
export function batchDelEmail(data) {
  return request({
    url: "/email/batchDelEmail",
    method: "POST",
    data,
  });
}
/*文件上传异常处理
 */
export function fileUploadExceptHandle(params) {
  return request({
    url: `/knowledge/space/fileUploadExceptHandle`,
    method: "GET",
    params,
  });
}
/* 30.获取收件箱未读数量 */
export function getRecieveEmailUnRead(params) {
  return request({
    url: "/email/getRecieveEmailUnRead",
    method: "GET",
    params,
  });
}
/* 31.获取星标邮件未读数量 */
export function getStarEmailUnRead(params) {
  return request({
    url: "/email/getStarEmailUnRead",
    method: "GET",
    params,
  });
}
