/**
 * @description: 查询文件地址
 * @return
 **/

export function getFileUrl(params) {
  return request({
    url: "/material/getByMaterialCode",
    method: "get",
    params,
  });
}
/**
 * @description 查询省级下边所有的市
 * @param {String} proCode
 */
export function selectByProvinceCode(proCode) {
  return request({
    method: "GET",
    url: "/train/district/selectByProvinceCode",
    params: { proCode: proCode },
  });
}
/**
 * @description 查询市下边的所有区县
 * @param {String} cityCode
 */
export function selectByCityCode(cityCode) {
  return request({
    method: "GET",
    url: "/train/district/selectByCityCode",
    params: { cityCode: cityCode },
  });
}
/**
 * @description查询所有的省份
 */
import request from "@/utils/request";
export function selectAllProvince(data) {
  return request({
    method: "GET",
    url: "/train/district/selectAllProvince",
    data,
  });
}
/**
 * @description: 根据类型查询用户信息 type 1姓名 ,2 身份证号 ,3 手机号
 * @return
 **/

export function getUserInfoByType(params) {
  return request({
    url: "/consume/real-name-authen/queryRealNameInfoByType",
    method: "get",
    params,
  });
}
//根据经纬度定位
export function getLocation(params) {
  return request({
    url: "/backend-common/geocoder/location",
    method: "GET",
    params: params,
  });
}
//批量查询文件地址 按顺序返回
export function batchQueryFileUrlBySort(params) {
  return request({
    url: "/backend-common/third/setOrder/batchQueryUrl",
    method: "GET",
    params: params,
  });
}
//批量查询文件地址 按顺序返回
export function getMediaUrlListByCodes(params) {
  return request({
    url: "/material/getByMaterialCode",
    method: "GET",
    params: params,
  });
}
/**
 * @description: 获取个人中心信息
 * @param {Number}
 */

export function getMyStatffInfo(params) {
  return request({
    method: "GET",
    url: "/staff/getMyStatffInfo",
    params: params,
  });
}
/*
 * 上传图片
   参数：file
 */
export function uploadImg(data) {
  return request({
    url: "/cdn/upload/uploadImg",
    method: "POST",
    data,
  });
}
/*
 * 获取全部在职员工
 */
export function getAllStaffList(params) {
  return request({
    url: "/staff/getAllStaffList",
    method: "GET",
    params,
  });
}
/*
 * 获取员工所属的部门领导人
 */
export function getDepartmentLeader(params) {
  return request({
    url: "/staff/getDepartmentLeader",
    method: "GET",
    params,
  });
}
/*
 * 获取全部员工
 */
export function getSelectAllList(params) {
  return request({
    url: "/staff/selectAll",
    method: "GET",
    params,
  });
}
/*
 * 入职邀请验证
 */
export function validEntryInfo(data) {
  return request({
    url: "/staff/validEntryInfo",
    method: "POST",
    data,
  });
}
/*
 * 入职信息填写
 */
export function saveStaffInfo(data) {
  return request({
    url: "/staff/saveStaffInfo",
    headers: { "Access-Token": data.accessToken },
    method: "POST",
    data,
  });
}
//根据字典码获取字典列表
export function getDictListByCode(params) {
  return request({
    url: `/notice/announcement/getDictListByCode`,
    method: "get",
    params: params,
  });
}
/*
 * 车辆列表
 */
export function queryCarList(params) {
  return request({
    url: "/cart/manage/cartList",
    method: "GET",
    params,
  });
}
// 获取加油卡列表
export function queryCardList(params) {
  return request({
    url: "/car/cardManger/queryCardList",
    method: "get",
    params,
  });
}
