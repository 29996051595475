import { login, logout, phoneLogin, imagecode, smsSend } from "@/api/userauth";
import { h5Login } from "@/api/account";
import { queryResourcesByCode } from "@/api/cdn/index.js";
import { queryUserInfo, sendCode } from "@/api/user";
import { getMyStatffInfo } from "@/api/common";
import Cookies from "js-cookie";

const state = {
  listResource: [],
  userName: "",
  staffCode: "",
  userInfo: null,
};
const mutations = {
  //资源
  changeListResource(state, payload) {
    let permission = [];
    permission = payload.map((item) => {
      return item.url || item.permission;
    });
    state.listResource = permission;
    localStorage.setItem("permission_H5", permission);
  },
  updateUserName(state, payload) {
    state.userName = payload;
  },
  updateStaffCode(state, payload) {
    state.staffCode = payload;
  },
  updateUserInfo(state, payload) {
    state.userInfo = payload;
  },
};
const actions = {
  /* 获取图片验证码 */
  imageCodeAction({ commit }, param) {
    return new Promise((resolve, reject) => {
      imagecode(param)
        .then((res) => {
          // localStorage.setItem("Authorization_OA_H5", res.data, {
          //   expires: 7,
          // });
          localStorage.setItem("Authorization_OA_H5", res.data)


          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 登录
  loginAction({ dispatch, commit }, param) {
    return new Promise((resolve, reject) => {
      login(param)
        .then((res) => {
          let { data, success } = res;

          localStorage.setItem("Authorization_OA_H5", data.token)

          localStorage.setItem("userName_H5", data.userName)

          localStorage.setItem("staffCode_H5", data.staffCode)

          if (data.token) {
            dispatch("getResource");
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 手机号登录
  phoneLoginAction({ dispatch, commit }, param) {
    return new Promise((resolve, reject) => {
      phoneLogin(param)
        .then((res) => {
          let { data, success } = res;

          localStorage.setItem("Authorization_OA_H5", data.token)
          localStorage.setItem("userName_H5", data.userName)
          localStorage.setItem("staffCode_H5", data.staffCode)

          if (data.token) {
            dispatch("getResource");
          }

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //微信授权登录
  h5LoginAction({ dispatch, commit }, param) {
    return new Promise((resolve, reject) => {
      h5Login(param)
        .then((res) => {
          let { data, success } = res;
          localStorage.setItem("Authorization_OA_H5", data.token);
          localStorage.setItem("username_H5", data.userName);
          localStorage.setItem("staffCode_H5", data.staffCode);
          localStorage.setItem("openId_H5", data.openId);
          if (data.token) {
            dispatch("getResource");
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /* 查询用户信息 */
  queryUserInfoAction({ commit }, param) {
    return new Promise((resolve, reject) => {
      queryUserInfo(param)
        .then((res) => {
          localStorage.setItem("username_H5", res.data.username, {
            expires: 7,
          });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async sendCodeAction({ dispatch, commit }, payload) {
    const res = await smsSend(payload);
    return res;
  },
  fedLogOut({ commit }, param) {
    localStorage.removeItem("Authorization_OA_H5");
    localStorage.removeItem("userName_H5");
    localStorage.removeItem("staffCode_H5");
    localStorage.removeItem("openId_H5");
    return new Promise((resolve, reject) => { });
  },
  /*获取资源*/
  async getResource({ dispatch, commit }) {
    const res = await queryResourcesByCode({
      staffCode: localStorage.getItem("staffCode_H5"),
    });
    if (res && res.code === 200) {
      commit("changeListResource", res.data);
    }
    return res;
  },
  //获取员工信息
  async getInfo({ commit }, params) {
    const res = await getMyStatffInfo();
    if (res) {
      let { data, code } = res;
      commit("updateUserInfo", data);
      commit("updateUserName", data.userName);
      commit("updateStaffCode", data.staffCode);
    }
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
