import * as questionApi from "@/api/question";
function checkErrCode(resp) {
  return resp ? (resp.code === 200 ? true : false) : false;
}
const state = {
  detail: null
};
const mutations = {
  setDetail(state, payload) {
    state.detail = payload;
  }
};
const actions = {
  //查询问卷详情
  questionDetail({ commit }, param) {
    return new Promise((resolve, reject) => {
      questionApi
        .questionDetail(param)
        .then((res) => {
          if (checkErrCode(res)) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  //抽奖
  getLuckyDraw({ commit }, param) {
    return new Promise((resolve, reject) => {
      questionApi
        .getLuckyDraw(param)
        .then((res) => {
          if (checkErrCode(res)) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((res) => {
          reject(res);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
