import request from "@/utils/request";
/*列表详情  */
export function questionDetail(params) {
  return request({
    method: "GET",
    url: "/question/detail",
    params,
  });
}

/* 填写问卷 */
export function fillQuestion(data) {
  return request({
    method: "POST",
    url: "/question/fillQuestion",
    data: data,
  });
}
/* 抽奖 */
export function getLuckyDraw(params) {
  return request({
    method: "GET",
    url: "/question/draw",
    params,
  });
}
/* 分页查询问卷列表（H5端） */
export function pageStaffXcx(data) {
  return request({
    method: "POST",
    url: "/question/pageStaffXcx",
    data: data,
  });
}
///
export function getPageMyPart(data) {
  return request({
    method: "POST",
    url: "/question/getPageMyPart",
    data: data,
  });
}
function getObject(rules, field, fieldValue) {
  const rule = rules.find((el) => el.field === field);
  const options = rule.options;
  const o = options.find((el) => el.value === fieldValue);
  return {
    [field]: o,
  };
}
