import axios from "axios";
import Cookies from "js-cookie";
import { APP_USER_SOURCE } from "@/config/constant";
import { Toast, Dialog } from "vant";
import router from "@/router";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000, // 请求超时时间
});

// 新建请求拦截器
service.interceptors.request.use(
  // 正常请求拦截
  (requestConfig) => {
    if (!requestConfig.headers["User-Source"]) {
      requestConfig.headers["User-Source"] = APP_USER_SOURCE;
      // if (localStorage.getItem("Authorization_OA_H5")) {
      if (localStorage.getItem("Authorization_OA_H5")) {
        // requestConfig.headers["Authorization"] = localStorage.getItem("Authorization_OA_H5"); // 让每个请求携带自定义token 请根据实际情况自行修改
        requestConfig.headers["Authorization"] = localStorage.getItem("Authorization_OA_H5"); // 让每个请求携带自定义token 请根据实际情况自行修改
      }
    }
    return requestConfig;
  },
  // 错误请求拦截
  (error) => {
    return Promise.reject(error);
  }
);

// 新建响应拦截器
service.interceptors.response.use(
  // 正常响应拦截
  (response) => {
    // console.log("正常响应拦截", response);

    const res = response.data;
    if (!res) {
      return Promise.reject(res);
    }
    if (!res.code) {
      return Promise.reject(res);
    }
    if (res.code == 401) {
      Dialog.confirm({
        title: "确定登出",
        message: "你已被登出，可以取消继续留在该页面，或者重新登陆",
      }).then((res) => {
        localStorage.removeItem("Authorization_OA_H5");
        localStorage.removeItem("staffCode_H5");
        localStorage.removeItem("userName_H5");
        localStorage.removeItem("openId_H5");
        router.replace("/login");
      });

      return;
    }
    if (res.code !== 200) {
      Toast(res.msg);
      return Promise.reject(res);
    }
    return response.data;
  },
  // 错误响应拦截
  (error) => {
    Toast("网络连接失败,请稍后再试!");
    return Promise.reject(error);
  }
);

export default service;
