import request from "@/utils/request";

//新增用户
export function addUser(data) {
  return request({
    url: "/pclogin/user/add",
    method: "post",
    data: data,
  });
}

//（云济端）编辑用户
export function editUser(data) {
  return request({
    url: "/pclogin/user/edit",
    method: "post",
    data: data,
  });
}

// 查询用户信息
export function queryUserInfo(data) {
  return request({
    url: "/pclogin/user/info",
    method: "post",
    data: data,
  });
}

//用户列表
export function queryUserList(data) {
  return request({
    url: "/pclogin/user/list",
    method: "post",
    data: data,
  });
}

//编辑用户
export function removeUser(data) {
  return request({
    url: "/pclogin/user/remove",
    method: "post",
    data: data,
  });
}

//启用禁用用户
export function enableUser(data) {
  return request({
    url: "/pclogin/user/enableUser",
    method: "post",
    data: data,
  });
}

//部门、用户列表
export function queryDepartmentPeople(data) {
  return request({
    url: "/pclogin/department/queryDepartmentPeople",
    method: "post",
    data: data,
  });
}

/**
 * @description: 登录
 * @param {Number}  phone 手机号
 */

export function sendCode(params) {
  return request({
    method: "GET",
    url: "/pclogin/sms/sendSMSCode",
    params: params,
  });
}
