import request from "@/utils/request";

//登录
export function login(data) {
  return request({
    url: "/account/login",
    // url: "/pclogin/userauth/login",
    method: "post",
    data: data,
  });
}

//退出登录
export function logout(params) {
  return request({
    url: "/pclogin/userauth/logout",
    method: "get",
    params: params,
  });
}
/**
 * @description: 登录
 * @param {Number}  phone 手机号
 * @param {String}  code    验证码
 *
 */

export function phoneLogin(data) {
  return request({
    method: "POST",
    url: "/account/login/phone",
    data: data,
  });
}
/* 生成图片验证码 */
export function imagecode(params) {
  return request({
    method: "GET",
    url: "/account/imagecode",
    responseType: "bob",
    params: params,
  });
}
/* 短信发送 */
export function smsSend(params) {
  return request({
    method: "GET",
    url: "/account/smsSend",
    responseType: "bob",
    params: params,
  });
}
/**
 * @description: 修改密码
 * @param {Number} password
 */

export function revisePassword(params) {
  return request({
    method: "GET",
    url: "/staff/revisePassword",
    params: params,
  });
}
/* 忘记密码 */
export function forgetPassword(params) {
  return request({
    method: "GET",
    url: "/staff/forgetPassword",
    params: params,
  });
}
/* 获取socket连接状态 */
export function getSocketStatus(params) {
  return request({
    method: "GET",
    url: "/getSocketStatus",
    params: params,
  });
}
