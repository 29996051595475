const state = {
  checkList: [], //选中参会人员列表
};
const mutations = {
  setCheckList(state, payload) {
    state.checkList = payload;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
