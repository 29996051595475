import request from "@/utils/request";

//发布公告
export function fileUrlToHtml(params) {
  return request({
    url: "/cdn/upload/fileUrlToHtml",
    method: "get",
    params: params,
  });
}

// 上传图片
export function uploadImg(data) {
  return request({
    url: "/cdn/upload/uploadImg",
    method: "post",
    data: data,
  });
}

// 上传图片返回绝对路径
export function uploadImgNoPrefix(data) {
  return request({
    url: "/cdn/upload/uploadImgNoPrefix",
    method: "post",
    data: data,
  });
}
// 根据用户Id查询所拥有的资源
export function queryResourcesByCode(params) {
  return request({
    url: "/resources/listByCode",
    method: "GET",
    params: params,
  });
}
